* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.buynew-category-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .buynew-category-Drawer {
        width: 265.34px !important;
    }

    .buynew-category-container {
        margin-bottom: 25px !important;
    }
}

.category-list-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    padding: 8px;
}

.category-list {
    margin: 14px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.category-list-info {
    flex: 5;
    display: flex;
    gap: 3px;
}

.category-list-Name {
    text-transform: capitalize;
}

.categories_title {
    font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
    font-weight: 600 !important;
}

.categories_subtitle {
    font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

.categories-btn-container {
    border-radius: 10px !important;
}

.categories-icon {
    color: #fff !important;
}

/* style for Buynew SubCategory */

.buynew-subcategory-container {
    margin-top: 20px;
    padding: 10px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.buynew-subcategory-box {
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.buynew-subcategory-box:hover {
    transform: translateY(-5px);
}

.subcategory-img {
    width: 100%;
    max-height: 100%;
    object-fit: fill;
    aspect-ratio: 1/3;
}