.annualcontract-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .annualcontract-container {
    margin-bottom: 25px;
  }

  .annualcontract-drawer {
    width: 265.34px !important;
  }
}

.text-capital {
  text-transform: capitalize;
}

.card-details {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.annualcontract-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.annual-contract-cards {
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width:600px) {
  .annual-contract-cards {
    margin: 0px 6px;
  }
}

.annual-contract-text {
  font-weight: 700 !important;
}

/* filter style */

.repair_contract-search-filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.repair_contract-searchbar-textfield {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.repair_contract-search-textfield {
  margin-top: 0px !important;
}

.repair_contract-filtericon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

.repair_contract-filter-container {
  position: sticky;
  top: 64px;
  z-index: 2;
  padding: 0px 8px;
}

@media screen and (max-width:600px) {
  .repair_contract-filter-container {
    top: 55px;
  }
}

.repair_contract-search-container {
  padding: 12px 0px;
}

@media screen and (min-width:960px) {
  .repair_contract-filter-container {
    width: calc(100% - 265.34px);
    margin-left: auto;
  }
}

@media screen and (min-width:960px) {
  .repair_contract-search-container {
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.repair_contract-dialog-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  padding: 8px 16px !important;
  margin: 10px 0px !important;
  color: #fff !important;
}