* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.jobapplicantslist-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .jobapplicantslist-Drawer {
        width: 265.34px !important;
    }

    .jobapplicantslist-container {
        margin-bottom: 25px !important;
    }
}

.applicants-Picture {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
    object-fit: cover;
}

.jobapplicantslist-card {
    margin: 14px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.jobapplicants-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}

.applicantsname-text {
    text-transform: capitalize;
}

.bold-text {
    font-weight: 700;
}

.applicant-title {
    font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
    font-weight: 600 !important;
}

.applicant-subtitle {
    font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

/* dialog style is here */

.applicant-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.applicant-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.applicants-details {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 2px; */
}

.applicants-img {
    width: 45px !important;
    height: 45px !important;
    border-radius: 50%;
    object-fit: cover;
}

.applicant-name {
    text-transform: capitalize;
}

.dialog-title {
    font-weight: 500 !important;
    font-size: 18px !important;
}

.applicant-uploaded-container {
    padding: 0px 10px;
}

.dialog-uploaded-icon {
    width: 60px !important;
    height: 60px !important;
    cursor: pointer;
}

.applicants-btn {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    padding: 8px 16px !important;
    color: #fff !important;
}