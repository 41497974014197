* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.jobspaper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.postjobs-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .drawer-leftside {
    width: 265.34px !important;
  }

  .postjobs-container {
    margin-bottom: 25px !important;
  }
}

.jobs-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.jobs-button:disabled {
  background-color: #535659 !important;
}

@media screen and (max-width:600px) {
  .jobspaper-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.skills-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 10px;
}
