* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.manage-training-container {
  max-width: 700px;
  margin-bottom: 100px !important;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .manage-training-Drawer {
    width: 265.34px !important;
  }
}

@media screen and (min-width:960px) {
  .manage-training-container {
    margin-bottom: 60px !important;
  }
}

.manage-training-card {
  height: 100%;
  margin: 0px 10px;
}

.manage-training-card-container {
  margin-top: 20px;
}

.manage-training-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 4px;
}

.manage-training-tags {
  border: 2px solid #002E6E !important;
}

.manage-training-textcolor {
  color: #00000099 !important;
}

.managetraining-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.manage-training-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.manage-training-title {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.manage-training-title-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.manage-trainingdetails-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 6px;
}

.manage-trainer-details {
  display: flex;
  justify-content: space-between;
  gap: 15px
}

.manage-trainer-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.manage-training-text{
  width: 100%;    
}
  
.read-or-hide-manage-training-description{
  color:#002E6E;
  cursor: pointer; 
}

.managetraining-btn{
  margin-left: auto !important;
}

.managetraining-button {
  background-color: #002E6E !important;
  margin-right: 10px !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
}
