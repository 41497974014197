* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.verification-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  padding: 0px 10px;
}

@media screen and (min-width:960px) {
  .verification__Drawer {
    width: 265.34px !important;
  }

  .verification-container {
    margin-bottom: 25px !important;
  }
}

.btn-container {
  margin-top: 25px;
}

.verify-img-container{
  height: 375px;
}

.verify-img {
  width: 100%;
  max-height: 100%;
  object-fit: fill;
  aspect-ratio: 1/3;
}

.verify-btn-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) { 
.verify-btn-container{
 flex-direction: column;
}
}

.verify-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.verification-status-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding-top: 8px;
}

.verification-status{
  flex: 1;
}