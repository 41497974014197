* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.attendtraining-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .attendtraining--Drawer {
    width: 265.34px !important;
  }

  .attendtraining-container {
    margin-bottom: 25px !important;
  }
}

.attendtraining-card {
  height: 100%;
  cursor: pointer;
}

.attendtraining-card-container {
  margin-top: 20px !important;
}

.attendtraining-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 4px;
}

.training-tags {
  border: 2px solid #002E6E !important;
}

.attendtraining-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.attendtraining-address-icon {
  margin-right: 2px;
}

.training-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.training-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 14px;
}

.training-title {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.training-title-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.training_text {
  text-transform: capitalize;
  font-weight: 600 !important;
}

.training-icon{
  color:#002E6E;
  vertical-align: middle;
  margin-right: 4px;
 }

 .trainingdetails-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 6px;
}

/* style for recommendation cards */

.recommdation-card{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50%;
  gap:1rem; 
  overflow-x: auto;
  overscroll-behavior-inline:contain ;  
}

@media screen and (max-width:600px) {
  .recommdation-card{
    grid-auto-columns: 100%; 
    margin: 10px 4px;
  }
}

.trainer-details{ 
  display: flex;
  justify-content: space-between;
  gap: 15px
}

.trainer-info{
   flex: 5;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 3px;
}

.register-btn {
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
