* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.referpatient-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .referpatient-container {
    margin-bottom: 25px;
  }

  .referpatient-drawer {
    width: 265.34px !important;
  }
}

/* filter style */

.referpatient-search-filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.referpatient-searchbar-textfield {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.referpatient-search-textfield {
  margin-top: 0px !important;
}

.referpatient-filter-icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

.referpatient-filter-container {
  position: sticky;
  top: 64px;
  z-index: 2;
  padding: 0px 8px;
}

@media screen and (max-width:600px) {
  .referpatient-filter-container {
    top: 55px;
  }
}

.referpatient-search-container {
  padding: 12px 0px;
}

@media screen and (min-width:960px) {
  .referpatient-filter-container {
    width: calc(100% - 265.34px);
    margin-left: auto;
  }
}

@media screen and (min-width:960px) {
  .referpatient-search-container {
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

/* refer doctorlist style */

.refer-doctor-picture {
  width: 90px !important;
  height: 110px !important;
  border-radius: 10px !important;
  object-fit: cover;
}

@media screen and (min-width:600px) {
  .refer-doctor-picture {
    height: 125px !important;
  }
}

.refer-patient-picture {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
}

.refer-patient {
  margin: 14px 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.refer-patient-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.refer-patient-btn {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  gap: 20px;
  margin-top: auto;
}

.refer-btn {
  text-transform: capitalize !important;
  color: #fff !important;
  cursor: pointer !important;
  padding: 6px 20px !important;
  font-size: 16px !important;
  border-radius: 10px !important;
}

.refer-dialog-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  margin: 8px 0px !important;
  font-size: 16px !important;
  padding: 8px 16px !important;
  color: #fff !important;
}

.view-btn {
  text-transform: capitalize !important;
  cursor: pointer !important;
  font-size: 16px !important;
}

.refer-btn:disabled {
  background-color: #535659 !important;
}

@media screen and (max-width:600px) {

  .refer-btn,
  .view-btn {
    font-size: 14px !important;
  }
}

.refer-title-text {
  font-weight: 700 !important;
}

.refer-patient-title {
  font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
  font-weight: 600 !important;
}

.refer-patient-subtitle {
  font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

.refer-patient-description {
  font-size: clamp(0.9rem, 1.5vw + .5rem, 1.10rem) !important;
}

.patient-reports-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin-top: 10px;
}

.patient-document-upload-container {
  border: 2px dashed #002E6E;
  margin-top: 10px;
  border-radius: 12px;
}

.patient-document-upload-Icon {
  width: 80px !important;
  height: 80px !important;
  color: #002E6E;
}

.patient-reports-pdf-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #002E6E;
  border-radius: 12px;
  padding: 6px;
}

.patient-reports-pdf-container:nth-child(n+2) {
  margin-top: 10px;
}

.patient-reports-pdf-icon {
  width: 26px !important;
  height: 26px !important;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.patient-reports-pdf-filename {
  flex: 1;
  padding: 5px;
  color: white;
}