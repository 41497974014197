* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.observershipCards-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
  .observership-drawer {
    width: 265.34px !important;
  }

  .observershipCards-container {
    margin-bottom: 25px !important;
    height: calc(100vh - 95px);
  }
}

@media screen and (max-width:720px) {
  .observershipCards-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.observership-card {
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.observership-card-container {
  margin-bottom: 20px;
}

.observershipCards-button {
  color: #fff !important;
  margin-left: auto !important;
  margin-right: 5px !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  width: 100px;
}

@media screen and (max-width:375px) {
  .observershipCards-button {
    width: 70px;
  }
}

.internship-icon {
  vertical-align: middle;
  margin-right: 4px;
  margin-left: -4px;
}

.internship-address-icon {
  margin-right: 2px;
}

.observership-title {
  text-transform: capitalize;
  font-weight: 600 !important;
}

.observership-img {
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
}

.observership-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/* filter style */

.apply-observership-search-filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.apply-observership-searchbar-textfield {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.apply-observership-search-textfield {
  margin-top: 0px !important;
}

.apply-observership-filter-icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

.apply-observership-dialog-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.observership-fixed-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-top: 10px;
}

.observership-scrollable-container {
  flex: 1;
  overflow-y: auto;
  padding-top: 12px;
}

.observership-filter-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: transparent;
  gap: 10px;
  margin: 12px 0px;
}