* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.event-cards-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px !important;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
  .event-card-drawer {
    width: 265.34px !important;
  }

  .event-cards-container {
    margin-bottom: 25px !important;
    height: calc(100vh - 95px);
  }
}

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 10px 0px;
  position: sticky;
  /* Stick search bar to the top */
  top: 0;
  z-index: 100;
}

.event-cards-scroll {
  flex: 1;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari, and newer browsers */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.event-cards-scroll::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and newer browsers */
}

.event-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin: 16px 0px;
}

.event-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px;
}

@media screen and (min-width: 600px) {
  .event-cards {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row on medium screens */
  }
}

@media screen and (max-width:720px) {
  .event-cards-container {
    margin: 0px 6px;
  }
}

.view-details-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.event-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-filter-icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 5px;
}

.event-filter-dialog-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  padding: 8px 16px !important;
  margin: 10px 0px !important;
  color: #fff !important;
}

.event-organiser {
  font-size: 18px !important;
  font-weight: bold !important;
}
