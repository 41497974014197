* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.event-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.create-event-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .create-event-drawer {
    width: 265.34px !important;
  }

  .create-event-container {
    margin-bottom: 25px !important;
  }
}

.event-type-label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.event-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.event-button:disabled {
  background-color: #535659 !important;
}

@media screen and (max-width:600px) {
  .event-paper-container {
    margin-left: 6px;
    margin-right: 6px;
  }
}


.event-upload-container{
  border: 2px dashed #002E6E;
  margin-top: 10px;
  border-radius: 12px;
}

.event-upload-icon{
  width: 80px !important;
  height: 80px !important;
}
