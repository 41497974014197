.annualcontract-product-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

.annualcontract-product-paper-container {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (min-width:960px) {
  .annualcontract-product-container {
    margin-bottom: 25px;
  }

  .annualcontract-product-drawer {
    width: 265.34px !important;
  }
}

.text-capital {
  text-transform: capitalize;
}

.annual_product_info{
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-top: 10px;
}

.annualcontract-product-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.annualcontract-details-btn {
  text-transform: capitalize !important;
  border-radius: 10px !important;
  padding: 8px 16px!important;
  color: #fff !important;
}

.text {
  width: 100%;
}

.read-or-hide {
  color: #002E6E;
  cursor: pointer;
}

.packagetable-header {
  color: white !important;
  font-size: 16px !important;
}

.bold-text {
  font-weight: 700 !important;
}

@media screen and (max-width:600px) {
  .annualcontract-product-paper-container {
    margin-left: 6px;
    margin-right: 6px;
  }

  .annualcontract-product-title{
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }

}