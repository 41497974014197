.paper-container {
  padding: 10px;
  border-radius: 12px !important;
  margin: 12px 10px;
}

.dialog-button1 {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 8px 16px !important;
  color: #fff !important;
}

.user_profile-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .user_profile-Drawer {
    width: 265.34px !important;
  }

  .user_profile-container {
    margin-bottom: 25px !important;
  }
}

.user_profile-details {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.datepicker {
  border: 1px solid #ccc;
  padding: 4px 4px;
  border-radius: 8px;
}

.profile-container {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 10px 0px;
  margin-top: 10px !important;
}

.profile-text {
  padding-left: 10px;
  padding-right: 10px;
}

.profile_picture {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  object-fit: cover;
}

.user_profile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 6px;
}

.user_name {
  flex: 5;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
  margin: 18px 10px 14px 10px;
}

.iconbtn_container {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}

.icon_btn {
  padding: 0px !important;
}

.profile_btn {
  text-transform: capitalize !important;
  color: #fff !important;
}

.profile_text {
  text-transform: capitalize !important;
}

.user_profile_description {
  margin-bottom: 6px;
}

.profile_title {
  font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
  font-weight: 600 !important;
}

.profile_subtitle {
  font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

.profile_description {
  font-size: clamp(0.9rem, 1.5vw + .5rem, 1.10rem) !important;
}

.section_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.section_details {
  flex: 5;
}

.section_iconbtn_container {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}

.display_image_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image_container {
  flex: 0.4;
}

.profile-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
}

.image-container {
  position: relative;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  color: red;
  cursor: pointer;
}

.upload-image-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 12px 0px 2px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.view-user-title {
  font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
}

.specialities-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: transparent;
  gap: 10px;
}
