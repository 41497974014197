* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.shortpage-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
}

@media screen and (min-width:960px) {
    .shortpage-drawer {
        width: 265.34px !important;
    }

    .shortpage-container {
        margin-bottom: 25px !important;
    }
}

.short-item {
    height: calc(100vh - 144px);
    padding: 10px;
    width: 100%;
    position: relative;
    scroll-snap-align: start;
    display: block;
}

@media screen and (min-width: 960px) {
    .short-item {
        height: 90vh;
    }
}

.shortpage-video {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
}

.short-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    z-index: 1;
}

.short-control-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
}

.short-report-container {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
}

.short-report-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
}

.short-actions {
    position: absolute;
    bottom: 85px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.short-action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.short-info {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    bottom: 24px;
    left: 24px;
    border-radius: 10px;
    width: calc(100% - 80px);
    padding: 6px;
}

.short-user-avatar {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    object-fit: cover;
    margin-top: 8px;
}

.short-caption-container {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 50px);
    overflow: hidden;
}

.short-caption {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    gap: 4px;
    padding: 8px;
    max-height: 3.1em;
    transition: max-height 0.3s ease-out, background-color 0.3s ease-out;
    background-color: transparent;
}

.short-caption.expanded {
    max-height: 15rem;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    overflow-y: auto; /* Enable scrolling */
    padding-right: 8px;
}

/* Custom scrollbar for better UX */
.short-caption.expanded::-webkit-scrollbar {
    width: 5px;
}

.short-caption.expanded::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.short-caption.expanded::-webkit-scrollbar-track {
    background: transparent;
}

.short-buttons {
    color: white !important;
}

.short-expand-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
    position: absolute !important;
    bottom: 8px;
    right: 8px;
    z-index: 10;
}