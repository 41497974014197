* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.posts-card {
  height: 100%;
  margin: 0px 10px;
}

.posts-card-container {
  margin-top: 20px;
}

.posts-card-like-icon {
  margin-right: 10px !important;
}

.createpost-container {
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}

.createpost-btn {
  border-radius: 22px !important;
  text-transform: inherit !important;
  background-color: #002E6E !important;
  color: #fff!important;
}