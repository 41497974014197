.product-details-container {
    max-width: 700px;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }
  
  .product-details-paper-container {
    padding: 12px;
    border-radius: 10px !important;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  @media screen and (min-width:960px) {
    .product-details-container {
      margin-bottom: 25px;
    }
  
    .product-details-drawer {
      width: 265.34px !important;
    }
  }
  
  .text-capital {
    text-transform: capitalize;
  }
  
  .product-details-description{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 10px;
  }
  
  .product-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  
  .product-details-btn {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 8px 16px!important;
  }
  
  .text {
    width: 100%;
  }
  
  .read-or-hide {
    color: #002E6E;
    cursor: pointer;
  }
  
  .bold-text {
    font-weight: 700 !important;
  }
  
  .product-details-grid-container {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 14px;
  }
  
  .product-details{
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px 0px;
  }
  
  .product-subtitle{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  
  .product-details-text {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  
  @media screen and (max-width:600px) {
    .product-details-paper-container {
      margin-left: 6px;
      margin-right: 6px;
    }
  
    .product-title{
      font-size: 1.25rem !important;
      font-weight: 600 !important;
    }
  
  }
  