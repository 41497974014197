* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .organize-training-paper-container {
    padding: 12px;
    border-radius: 10px !important;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .organize-training-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }
  
  @media screen and (min-width:960px) {
    .training-drawer {
      width: 265.34px !important;
    }

    .organize-training-container {
      margin-bottom: 25px !important;
    }
  }
  
  .organize-training-button {
    background-color: #002E6E !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    margin: 20px 0px 8px 0px !important;
    padding: 8px 16px !important;
  }
  
  .trainingeditor-container{
    height: 300px;
    margin-top:10px !important ;
    margin-bottom: 60px !important;
  }
  
  .training-texteditor{
    height: 100%;
    border: none;
  }
  
  @media screen and (max-width:492px) {
    .trainingeditor-container {
      margin-bottom: 80px !important;
    }
  }
  @media screen and (max-width:351px) {
    .trainingeditor-container {
      margin-bottom: 105px !important;
    }
  }

  .radio-btn-label{
    margin-right: 12px;
  }

  @media screen and (max-width:325px) {
    .radio-btn-label {
      margin-right: 7px;
    }
  }

  @media screen and (max-width:600px) {
    .organize-training-paper-container {
      margin-left: 6px;
      margin-right: 6px;
    }
  }