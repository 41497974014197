.likesuser-Picture{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.likes-container{
   margin: 10px 0px; 
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px
}

.like-info{
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.likesuser-profileName{
   font-weight: 500; 
}

.likeuser-name{
  color:#00000099 !important;
}

.date{
   flex:1;
   align-self: flex-end;
   color: #00000099; 
   font-size: 12px;
}

.write-comments{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 10px 0px;
}

.comment-Input{
    flex: 5;
    padding: 10px;
    border: 1px solid gray;
    background-color: transparent;
    color:black;
    border-radius: 10px;
}

.follow-Button {
    background-color: #33cac3 !important;
    font-size: 16px !important;
    border-radius: 12px !important;
    text-transform: capitalize !important;
    cursor: pointer !important;
    width:100px;
    text-align: center;
  }

  @media screen and (max-width:425px) {
    .follow-Button {
      font-size: 12px !important;
      width: 80px;
    }
  }

  .following-Button {
    background-color: #333333c7 !important;
  }
