* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.my-booking-container {
    max-width: 700px;
    width: 100%;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .my-booking-drawer {
        width: 265.34px !important;
    }

    .my-booking-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

.my-booking-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.my-booking-content-container {
    flex: 1;
    overflow-y: auto;
}

/* event pass style */

@media screen and (min-width:600px) { 
    .eventpass-card {
      height: 140px !important;
    }
  }
  
  .eventpass-card {
    display: flex;
    height: 130px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .eventpass-img-container {
    flex: 1;
  }
  
  .eventpass-img {
    width: 100%;
    object-fit: fill;
    height: 100%;
  }
  
  .eventpass-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    padding: 10px;
  }
