* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.view-magazine-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .view-magazine-drawer {
    width: 265.34px !important;
  }

  .view-magazine-container {
    margin-bottom: 25px !important;
  }
}

/* Medical Association Nameslist style */

.medical-association-Name {
  text-transform: capitalize;
  font-weight: 600 !important;
}

.medical-association-container {
  margin-top: 20px;
  padding: 10px;
  display: grid;
  gap: 22px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.medical-association-names_list {
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.medical-association-names_list:hover {
  transform: translateY(-10px);
}

#medical-association-logo {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  aspect-ratio: 1/3;
}

.association_names_container{ 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  border:2px solid red;

}                                                                                                                                                            

/* Medical Association Yearlist style */

.medical-association-yearlist-container {
  margin-top: 20px;
  padding: 10px;
  display: grid;
  gap: 22px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.medical-association-year_list {
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.medical-association-year {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.medical-association-year_list:hover {
  transform: translateY(-10px);
}

.medicalyear_text {
  text-transform: capitalize;
  font-weight: 600 !important;
}