* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.homeScreen-Container {
  max-width: 700px;
  margin-bottom: 100px !important;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .left-Drawer {
    width: 265.34px !important;
  }
}

.main-Div {
  border-radius: 10px;
  overflow: hidden;
  margin: 12px 4px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (min-width:600px) {
  .main-Div {
    border: 2px solid #00B9F1;
  }
}

.header-Div {
  background-color: #00B9F1;
  color: #fff;
  padding: 10px;
}

.Avatar-style {
  background-color: #fff !important;
  width: 60px !important;
  height: 60px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.icon-style {
  width: 32px !important;
  height: 32px !important;
  color: #002E6E;

}

.reacticon-style {
  width: 35px !important;
  height: 35px !important;
  color: #002E6E;

}

@media screen and (min-width:960px) {
  .homeScreen-Container {
    margin-bottom: 60px !important;
  }
}

.grid-container {
  padding: 10px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
}

.card-box {
  padding-top: 4px;
}

.sub_title{
  font-size: clamp(0.8rem,1.5vw + .5rem,1rem) !important ;
}

.main_title{
  font-size: clamp(1.25rem,2.5vw + .5rem,1.5rem) !important ;
}
 
@media screen and (max-width:600px) {

  .grid-container {
    padding: 8px;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .card-box {
    padding: 0px;
  }

  .Avatar-style {
    width: 46px !important;
    height: 46px !important;
  }
  
  .indicator{
    left: 62% !important; 
    padding: 10px !important;                                                                                                                                                                                                                                                                    
  }
  
  .noti_count{
    font-size: 12px;
  }

}

.base {
  position: relative;
  border: 1px solid transparent;
}

.indicator {
  width: 26px;
  height: 26px;
  padding: 12px;
  position: absolute;
  top: 0%;
  right: 0%;
  left: 60%;
  bottom: 60%;
  background-color: red;
  border: 3px solid #ebf5f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.noti_count {
  color: aliceblue;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 0.2em;
}

::-webkit-scrollbar-track {
  background-color: #dad4d4;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(120, 60%, 94%);
}