* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.all_magazines-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .all_magazines--Drawer {
    width: 265.34px !important;
  }

  .all_magazines-container {
    margin-bottom: 25px !important;
  }
}

.all_magazines-card {
  height: 100%;
  cursor: pointer;
}

.all_magazines-card-container {
  margin-top: 10px !important;
}

.all_magazines-icon {
  width: 20px !important;
  height: 20px !important;
  color: #002E6E;
  margin-right: 6px;
  vertical-align: middle;
}

.all_magazines-downloadicon {
  color: #002E6E;
  vertical-align: middle;
}

.all_magazines-title {
  text-transform: capitalize;
  font-weight: 600 !important;
}

.all_magazines-btn {
  text-transform: capitalize !important;
  border-radius: 10px !important;
  background-color: #002E6E !important;
  padding: 8px 16px !important;
  margin: 6px 0px!important;
}