* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.notification-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
}

@media screen and (min-width:960px) {
  .left__Drawer {
    width: 265.34px !important;
  }

  .notification-container {
    margin-bottom: 25px !important;
  }
}

.notification-info {
  padding: 15px;
  border-radius: 15px !important;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}