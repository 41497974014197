* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .newproductcards-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }
  
  @media screen and (min-width:960px) {
    .newproductcards--Drawer {
      width: 265.34px !important;
    }

    .newproductcards-container {
      margin-bottom: 25px !important;
    }
  }

  .newproduct-card {
    height: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
  
  @media screen and (max-width:600px) {
    .newproduct-card {
      margin: 0px 6px;
    }
  }

  .newproduct-text {
    font-weight: 700 !important;
  }
  
  .newproduct-text-capital {
    text-transform: capitalize;
  }

  .newproduct-details {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .newproduct-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  /* filter style */

.launchproduct-search-filter-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.launchproduct-searchbar-textfield{
   flex: 5;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.launchproduct-search-textfield {
  margin-top: 0px !important;
}

.buynew-filter-icon {
  width:30px !important;
  height:30px !important;
  margin-right: 5px;
}

.launchproduct-filter-container {
  position: sticky;
  top: 64px;
  z-index: 2;
  padding: 0px 8px;
}


@media screen and (max-width:600px) {
  .launchproduct-filter-container {
    top: 55px;
  }
}

.launchproduct-search-container {
  padding: 12px 0px;
}

@media screen and (min-width:960px) {
  .launchproduct-filter-container {
    width: calc(100% - 265.34px);
    margin-left: auto;
  }
}

@media screen and (min-width:960px) {
  .launchproduct-search-container {
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

  /* Style For New Product Details */

  .newproduct-paper-container {
    padding: 12px;
    border-radius: 10px !important;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text-capital {
    text-transform: capitalize;
  }
  
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .newproductlaunch_info{
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 18px;
  }
  
  .newproduct-btn {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 8px 16px!important;
  }
  
  .text {
    width: 100%;
  }
  
  .read-or-hide {
    color: #002E6E;
    cursor: pointer;
  }
  
  .bold-text {
    font-weight: 700 !important;
  }

  @media screen and (max-width:600px) {
    .newproduct-paper-container {
      margin-left: 6px;
      margin-right: 6px;
    }
  
    .title{
      font-size: 1.25rem !important;
      font-weight: 600 !important;
    }
  
  }
  