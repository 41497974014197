* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.video-feed-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    flex-grow: 1;
}

@media screen and (min-width: 960px) {
    .video-feed-drawer {
        width: 265.34px !important;
    }

    .video-feed-container {
        margin-bottom: 25px !important;
    }
}

.video-item {
    height: calc(100vh - 144px);
    padding: 10px;
    width: 100%;
    position: relative;
    scroll-snap-align: start;
    display: none;
}

.video-item.active {
    display: block;
}

@media screen and (min-width: 960px) {
    .video-item {
        height: 90vh;
    }
}

.reel-video {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
}

.video-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    z-index: 1;
}

.video-control-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
}

.video-report-container {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
}

.video-report-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
}

.video-back-container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}

.video-actions {
    position: absolute;
    bottom: 85px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.video-action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-info {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    bottom: 24px;
    left: 24px;
    border-radius: 10px;
    width: calc(100% - 80px);
    padding: 6px;
}

.user-avatar {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    object-fit: cover;
    margin-top: 8px;
}

.video-caption-container {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 50px);
    overflow: hidden;
}

.video-caption {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    gap: 4px;
    padding: 8px;
    max-height: 3.1em;
    transition: max-height 0.3s ease-out, background-color 0.3s ease-out;
    background-color: transparent;
}

.video-caption.expanded {
    max-height: 15rem;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    overflow-y: auto; /* Enable scrolling */
    padding-right: 8px;
}

/* Custom scrollbar for better UX */
.video-caption.expanded::-webkit-scrollbar {
    width: 5px;
}

.video-caption.expanded::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.video-caption.expanded::-webkit-scrollbar-track {
    background: transparent;
}

.video-buttons {
    color: white !important;
}

.expand-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
    position: absolute !important;
    bottom: 8px;
    right: 8px;
    z-index: 10;
}

/* manage shorts styling */

.manage-shorts-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .manage-shorts-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

.manage-shorts-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.manage-shorts-content-container {
    flex: 1;
    overflow-y: auto;
}

.shorts-container {
    display: grid;
    gap: 16px;
    padding: 16px;
    grid-template-columns: repeat(2, 1fr);
    cursor: pointer;
}

@media screen and (min-width: 600px) {

    .shorts-container {
        grid-template-columns: repeat(3, 1fr);
    }

}

/* Comment Dialog styling */

.scrollable-comments-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.comment-input-container {
    display: flex;
    align-items: center;
    gap: 16px;
    border-top: 1px solid #ddd;
    padding: 8px 0;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.comment-textfield {
    flex: 1;
}

.comment-list {
    flex: 1;
    overflow-y: auto;
}

.comment-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 16px;
}

.comment-avatar {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    object-fit: cover;
}

.comment-content {
    flex: 1;
}

.comment-button {
    margin-top: 10px !important;
    color: white !important;
    padding: 12px !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

/* Report Short Dialog styling */

.report-button {
    border-radius: 10px !important;
    text-transform: capitalize !important;
    padding: 10px 16px !important;
    color: #fff !important;
    margin: 10px 0px !important;
}