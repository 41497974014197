* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.newproduct-catelog-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px !important;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .newproduct-catelog-Drawer {
        width: 265.34px !important;
    }

    .newproduct-catelog-container {
        margin-bottom: 25px !important;
    }
}

.brand-title {
    text-transform: capitalize;
    font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
    font-weight: 600 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.store-brands-container {
    margin: 10px 0px;
    padding: 10px;
    display: grid;
    gap: 22px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.brands-list-box {
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.brands-list-box:hover {
    transform: translateY(-10px);
}

#brand-logo {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    aspect-ratio: 1/3;
}