* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.storyBoard-Container {
  max-width: 700px;
  margin-bottom: 35px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

.storyBoard-tabs {
  position: sticky;
  top: 0;
  z-index: 1;
}

.storyBoard-content {
 overflow-y: auto;
 max-height: calc(100vh - 200px);
}

@media screen and (min-width:960px) {
  .Drawer {
    width: 265.34px !important;
  }

  .storyBoard-Container {
    margin-bottom: 25px !important;
  }

  .storyBoard-content {
    max-height: calc(100vh - 155px);
   }
}


.storyboard-button {
  background-color: #002E6E !important;
  margin-left: auto !important;
  margin-right: 5px !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  width: 100px;
}

.readMore-button {
  width: 120px;
}

@media screen and (max-width:375px) {
  .readMore-button {
    width: 100px !important;
  }
}

@media screen and (max-width:375px) {
  .storyboard-button {
    font-size: 12px !important;
    width: 70px;
  }
}

.jobSkill-chips {
  border: 2px solid #002E6E !important;
  margin-left: 6px;
}

.storyboard-card {
  height: 100%;
  margin: 0px 10px;
}

@media screen and (max-width:600px) {
  .storyboard-card {
    margin: 0px 6px;
  }
}

.storyboard-text {
  text-transform: capitalize;
}

.storyboard-title {
  font-weight: 600!important;
}

.storyboard-card-container {
  margin-top: 20px;
}

.storyboard-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 4px;
}

.address-icon {
  margin-right: 2px;
}

.card-textcolor {
  color: #00000099 !important;
}

.like-icon {
  margin-right: 10px !important;
}

.storyboard-eventdate-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border:2px solid #002E6E;
  border-radius: 10px;
  padding: 6px 10px;
}

/* Advertisement-section */

.advertisement-container {
  margin: 150px auto auto 20px;
  flex: 0.5;
}

.advertisement-img-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

.ad-img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  aspect-ratio: 3/3;
}

.report-ad{
  position: absolute;
  bottom: -16px;
  right: 6px;
  color: #002E6E;
  cursor: pointer;
}

.form-control-radio{
  margin-top: 0px !important;
}

.report-ad-btn {
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font-size: 16px !important;
  margin: 8px 0px !important ;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* storyboard training style  */

.storyboardtraining-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 4px;
}

.storyboardtraining-tags {
  border: 2px solid #002E6E !important;
}

.storyboardtraining-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.storyboardtraining-textcolor {
  color: #00000099 !important;
}

.attendtraining-address-icon {
  margin-right: 2px;
}

.storyboardtraining-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.storyboardtraining-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.storyboardtraining-title {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.storyboardtraining-title-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.storyboardtraining-icon{
  color:#002E6E;
  vertical-align: middle;
  margin-right: 4px;
 }

 .storyboardtrainingdetails-icon {
  color: #002E6E;
  vertical-align: middle;
  margin-right: 6px;
}
