* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.searchuser-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .searchuser-drawer {
    width: 265.34px !important;
  }

  .searchuser-container {
    margin-bottom: 25px !important;
  }
}

.search-user-div {
  position: sticky;
  top: 60px;
  z-index: 2;
  background-color: #fff!important;
}

.user-searchbar {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.searchbar_with_smallspacing {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

@media screen and (max-width:960px) {
  .search-user-div {
    position: sticky;
    top: 52px;
  }
}

@media screen and (min-width:960px) {
  .search-user-div {
    width: calc(100% - 265.34px);
    margin-left: auto;
  }
}

@media screen and (min-width:960px) {
  .user-searchbar {
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.searchuser-Picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.searchuser-details-container {
  margin: 14px 4px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  border-radius: 10px;
}

.searchuser-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.searchuser-Name {
  text-transform: capitalize;
  font-weight: 600 !important;
}

.searchuser-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.searchuser-btn {
  text-transform: capitalize !important;
  border-radius: 10px !important;
  background-color: #002E6E !important;
  color: #fff !important;
}

.searchuser-btn-container{
  margin-top: 10px;
  display: flex;
  flex: 1;
  align-self: flex-end;
  gap:10px;
}

.userwith-icon-container{
  display: flex;
}

.usertype-icon{
  width: 22px !important;
  height: 22px !important;
  color: #002E6E;
  margin-left: 6px;
  vertical-align: middle;
}

.medical-association-text{
  color: #002E6E;
  margin-left: 4px !important;
}
