.identity-document-container {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #919191;
  border-radius: 20px;
  overflow: hidden;
}

.identity-document-upload-Icon {
  width: 100px !important;
  height: 100px !important;
}

.identity-document-image-container {
  position: relative;
  height: 300px;
  border: 2px solid #919191;
  border-radius: 20px;
  overflow: hidden;
}

.identity-document-image {
  width: 100%;
  max-height: 100%;
  object-fit: fill;
  aspect-ratio: 1/3;
}

.identity-document-delete-Icon {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 20px;
  cursor: pointer;
}

.identity-submit-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}