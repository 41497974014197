* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .post_magazinepaper-container {
    padding: 12px;
    border-radius: 10px !important;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .post_magazine-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }
  
  @media screen and (min-width:960px) {
    .post_magazine-drawer {
      width: 265.34px !important;
    }

    .post_magazine-container {
      margin-bottom: 25px !important;
    }
  }

  @media screen and (max-width:600px) {
    .post_magazinepaper-container {
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .post_magazine-button {
    background-color: #002E6E !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    margin: 20px 0px 8px 0px !important;
    padding: 8px 16px !important;
  }

  .magazine-title{
    font-weight: 500 !important; 
    font-size: 18px !important;
 }
 .magazine_upload-Container{
  border: 2px dashed #002E6E;
  margin-top: 5px;
  border-radius: 12px;
}

.magazine_upload--Icon{
  width: 80px !important;
  height: 80px !important;
  color:#002E6E ;
}

.magazine_upload-FileInfo{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0px;
  background-color: #002E6E;
  border-radius: 12px;
}

.magazine_upload-FileIcon{
  width: 26px !important;
  height: 26px !important;
  color: white;
  margin: 0px 5px;
}

.magazine_file-Name{
  flex: 1;
  padding: 5px;
  color: #fff;
}

.magazine_uploaddate{
  border: 1px solid #ccc;
  padding: 4px 4px;
  border-radius: 8px;
} 
  