.video-upload-container {
    max-width: 700px;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    height: calc(100vh - 155px);
}

.video-upload-paper-container {
    padding: 12px;
    border-radius: 10px !important;
    margin: 12px 0px;
    position: relative;
}

@media screen and (min-width:960px) {
    .video-upload-container {
        margin-bottom: 25px;
        height: calc(100vh - 95px);
    }

    .video-upload-drawer {
        width: 265.34px !important;
    }

    .media-container video {
        height: 400px !important;
    }
}

.video-upload-btn {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 8px 16px !important;
}

.media-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.media-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
}

.media-container video {
    width: 100%;
    height: 300px;
    max-width: 100%;
    object-fit: cover;
}

.no-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.no-video-icon {
    width: 90px !important;
    height: 90px !important;
}

.video-overlay {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
}

.clear-icon {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
    cursor: pointer;
    font-size: 24px !important;
}