.answer-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .writer-info {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

.post-btn{
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font-size: 16px !important;
  margin:10px 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.writeanswer-editor-container{
  height: 180px;
  margin-top:10px !important ;
  margin-bottom: 50px !important;
}

.writeanswer-editor{
  height: 100%;
  border: none;
}