* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.manage-training-table-container {
  max-width: 700px;
  margin-bottom: 100px !important;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .manage-training-table-Drawer {
    width: 265.34px !important;
  }
}

@media screen and (min-width:960px) {
  .manage-training-table-container {
    margin-bottom: 60px !important;
  }
}

.send-response-btn-container{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.send-response-btn{
    background-color: #002E6E !important;
    border-radius: 10px !important;
    text-transform: inherit !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.bulk-response-textarea{
  height: 300px;
  margin-top:10px !important ;
  margin-bottom: 60px !important;
}

.bulk-response-editor{
  height: 100%;
  border: none;
}

.bulk-response-button {
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  margin: 8px 0px !important ;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}