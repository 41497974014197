* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.manage_magazines-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .manage_magazines-drawer {
    width: 265.34px !important;
  }

  .manage_magazines-container {
    margin-bottom: 25px !important;
  }
}

.manage_magazines-button {
  background-color: #db0505 !important;
  margin-right: 10px !important;
  border-radius: 12px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
}

.manage_magazines-title {
  text-transform: capitalize;
  font-weight: 600 !important;
}
