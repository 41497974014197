.paper {
    padding: 20px;
    border-radius: 20px !important;
    margin: 5px 0px;
}

.text-field {
    margin-bottom: -4% !important;
}

.text-field11 {
    margin-top: 12% !important;
    margin-bottom: -3% !important;
}

.text-fieldpassw {
    margin-top: 1% !important;

}

.text-fielddropdown {
    margin-top: -3% !important;

}

.btn-primary {
    color: #fff !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.btn-primary:disabled {
    background-color: #535659 !important;
}

.MuiOutlinedInput-root {
    border-radius: 10px !important;
}

.dialog-button {
    color: #fff !important;
    border-radius: 10px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.textfield-topmargin {
    margin-top: 10px !important;
}