* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.event-details-wrapper {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .event-details-drawer {
    width: 265.34px !important;
  }

  .event-details-wrapper {
    margin-bottom: 25px !important;
  }
}

.event-details-container {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-between;
  gap: 16px;
  padding: 12px;
  max-width: 100%;
}

.event-image {
  max-width: 350px;
  width: 100%;
  height: auto;
  max-height: 400px;
  border-radius: 12px;
}

.event-schedule-image {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 350px;
  border-radius: 12px;
}

.event-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  overflow: hidden;
}

.event-link a {
  word-break: break-all;
  max-width: calc(100% - 30px);
}

.event-title {
  font-size: 21px !important;
  font-weight: bold !important;
  max-width: 100%;
}

.event-subtitle {
  font-size: 18px !important;
  font-weight: bold !important;
}

.event-time,
.event-location,
.event-type,
.event-link {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

@media screen and (max-width: 600px) {
  .event-details-container {
    flex-direction: column;
    padding: 12px 12px 0px 12px;
  }

  .event-image {
    max-width: 100%;
  }

  .event-info {
    gap: 5px;
  }

  .event-details {
    gap: 5px;
  }

  .price-button-wrapper {
    margin-top: 4px;
  }

}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 8px;
  padding: 0px 12px;
}

.price-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.register-button {
  flex: 1;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  text-transform: capitalize !important;
}

.price-divider {
  display: none;
}

@media screen and (min-width: 600px) {
  .event-info {
    padding: 18px;
    border-radius: 12px;
    border: 1px solid #e1e3e6;
  }

  .price-divider {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    margin: 4px 0px;
  }

}