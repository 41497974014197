* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.questionandanswer-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .left-Menu-Drawer {
    width: 265.34px !important;
  }

  .questionandanswer-container {
    margin-bottom: 25px !important;
  }
}

.questionandanswer-paper {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width:600px) {
  .questionandanswer-paper {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.responser-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.responser-Picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.responser-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.question-container {
  margin: 10px 0px;
}

.question-text {
  font-weight: bold !important;
  color: black !important;
  text-align: left;
}

.action-btn-container {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.action-btn-iconwith-text {
  display: flex;
  gap: 5px;
  padding: 6px 10px;
  cursor: pointer;
}

.action-btn-only-likeicon {
  border: 2px solid #002E6E;
  border-radius: 22px;
}

.action-icons {
  width: 20px !important;
  height: 18px !important;
  color: #636466;
  vertical-align: middle;
}

.action-react-icons {
  width: 20px !important;
  height: 20px !important;
  color: #002E6E;
}

.link-color {
  color: #002E6E !important;
}

.askquestion-container {
  margin: 12px 10px;
  display: flex;
  justify-content: flex-end;
}

.askquestion-btn {
  border-radius: 22px !important;
  text-transform: inherit !important;
  background-color: #002E6E !important;
  color: #fff!important;
}

.add-question-btn {
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.tooltip{
  position: relative;
}

.tooltip:hover::after{
  content: attr(data-tooltip);

  background-color: #002E6E;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  font-size: 0.9em;
  text-align: center;
  position: absolute;
  bottom: 100%;
  margin-bottom: 6px;
  width: 100px;
}

.all-answers-container{
  margin-left: auto;
}

/* ALL Answers Style Start Here */
.write-ans-container {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.answer-btn {
  border-radius: 22px !important;
  text-transform: capitalize !important;
  background-color: #002E6E !important;
  color: #fff!important;
}