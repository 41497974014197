* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.add-member-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .add-member-drawer {
        width: 265.34px !important;
    }

    .add-member-container {
        margin-bottom: 25px !important;
    }
}

.add-member-button {
    border-radius: 10px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    margin: 10px 0px !important;
    padding: 10px 16px !important;
    color: #fff !important;
}

.add-member-btn-container {
    display: flex;
    justify-content: flex-end;
}

.add-member-btn {
    text-transform: capitalize !important;
    color: #fff !important;
}