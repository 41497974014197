.que-ans-user-Picture{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.que-ans-comment{
   margin: 10px 0px; 
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 3px;
}

.que-ans-comment-info{
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.que-ans-comment-user-Name{
   font-weight: 500; 
}

.userinfo{
  display: flex;
  justify-content: space-between;
  gap: 12px; 
}

.user_details{
  flex:1;
  display: flex;
  flex-direction: column; 
}

.reply_container{
 margin-left: 55px; 
}

.que-ans-comment-reply{
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.que-ans-comment-date{
   flex:1;
   align-self: flex-end;
   font-size: 12px;
   margin-right: 5px !important;
}

.que-ans-comment-write-comments{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin: 10px 0px;
}

.que-ans-comment-Input{
    flex: 5;
}

.comment-textarea{
   margin-top: 0px!important; 
}

.send-Button {
    background-color: #002E6E !important;
    border-radius: 22px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    margin-right: 5px !important;
    color: #fff !important;
    font-size: 14px !important;
  }

  .reply-text{
    cursor: pointer;
    color: #002E6E;
  }

  .view-more-btn{
    border-radius: 22px !important;
    text-transform: inherit !important;
    color: #002E6E !important;

  }

  .comments-btn-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .comments-btn-iconwith-text {
    display: flex;
    gap: 5px;
    padding: 6px 10px;
    cursor: pointer;
  }

  .comments-btn-only-likeicon {
    border: 2px solid #002E6E;
    border-radius: 22px;
  }

  .comments-icons {
    width: 20px !important;
    height: 18px !important;
    color: #636466;
    vertical-align: middle;
  }