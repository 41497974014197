.messenger-container {
  display: flex;
  height: calc(100vh - 70px);

}

.chat-menu {
  flex: 4;
}

.chat-box {
  flex: 8;
}

.chat-menu-wrapper,
.chat-box-wrapper {
  padding-bottom: 10px;
  height: 100% !important;
}
/* 
.chat-menu-wrapper-padding {
  padding: 0px 0px 10px 10px;
}

.chat-box-wrapper-padding {
  padding: 0px 10px 10px 0px;
} */

.chat-container {
  width: 100vw;
  margin-bottom: 1px !important;
  margin-top: 66px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
}

@media screen and (min-width:960px) {
  .chat-container {
    width: calc(100vw - 252px);
    margin-right: 0px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .chat-box {
    display: none;
  }

  .chat-menu-wrapper,
  .chat-box-wrapper {
    padding: 0px;
  }

}

@media only screen and (max-width: 960px) {

  .messenger-container{
    height: calc(100vh - 146px);
  }

}

/* ::-webkit-scrollbar {
  width: 0.2em;
} */

/* ::-webkit-scrollbar-track {
  background-color: #dad4d4;
} */

/* ::-webkit-scrollbar-thumb {
  background-color: hsl(120, 60%, 94%);
} */