* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.referred-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .referred-container {
    margin-bottom: 25px;
  }

  .referred-drawer {
    width: 265.34px !important;
  }
}

.referred-doctor-picture{
  width: 90px !important;
  height: 110px !important;
  border-radius: 10px !important;
  object-fit: cover;
}

@media screen and (min-width:600px) {
  .referred-doctor-picture{
    height: 125px !important;
  }
}

.referred-picture{
  width: 48px !important;
  height: 48px !important;
  object-fit: cover;
}

.referred-patient{
 margin: 14px 10px;
 display: flex;
 justify-content: space-between;
 gap: 15px;
 border-radius: 10px;
 padding: 10px;
 box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
 cursor: pointer;
}

.referred-patient-info{
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.referred-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 8px 0px !important;
  padding: 8px 16px !important;
  color: #fff !important;
}

.referred-title-text {
  font-weight: 700 !important;
}

.referred-title {
 font-size: clamp(1rem, 1.5vw + .5rem, 1.25rem) !important;
 font-weight: 600 !important;
}

.referred-subtitle {
 font-size: clamp(0.94rem, 1.5vw + .5rem, 1.14rem) !important;
}

.referred-description {
  font-size: clamp(0.9rem, 1.5vw + .5rem, 1.10rem) !important;
 }

 .referred-to-me-title {
  font-size: clamp(1rem, 1.5vw + .5rem, 1.12rem) !important;
  font-weight: 600 !important;
}

.referral-text {
  width: 100%;
}

.referral-read-or-hide {
  cursor: pointer;
}

.patient-reports-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin-top: 10px;
}

.patient-document-upload-container{
  border: 2px dashed #002E6E;
  margin-top: 10px;
  border-radius: 12px;
}

.patient-document-upload-Icon{
  width: 80px !important;
  height: 80px !important;
  color:#002E6E ;
}

.patient-reports-pdf-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #002E6E;
  border-radius: 12px;
}

.patient-reports-pdf-container:nth-child(n+2) {
  margin-top: 10px;
}

.patient-reports-pdf-icon{
  width: 26px !important;
  height: 26px !important;
  color: white;
  margin: 0px 5px;
}

.patient-reports-pdf-filename{
  flex: 1;
  padding: 5px;
  color: white;
}

.upload-Filedetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
  background-color: #002E6E;
  border-radius: 12px;
}

.upload-Filedetails-Icon {
  width: 26px !important;
  height: 26px !important;
  color: #fff;
  margin: 0px 5px;
}

.upload-file-Name {
  flex: 1;
  padding: 5px;
  color: #fff;
}

.refer-doc-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin: 8px 0px;
}

.refer-image-container {
  position: relative;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
}

.refer-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
