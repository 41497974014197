* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.event-attendance-container {
    max-width: 700px;
    width: 100%;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .event-attendance-drawer {
        width: 265.34px !important;
    }

    .event-attendance-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

.attendees-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.attendees-content-container {
    flex: 1;
    overflow-y: auto;
}
