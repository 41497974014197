* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
  }
  
  .edit-training-paper-container {
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .edit-training-container {
    max-width: 700px;
    margin-bottom: 100px !important;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
  }
  
  @media screen and (min-width:960px) {
    .edit-training-drawer {
      width: 265.34px !important;
    }
  }
  
  @media screen and (min-width:960px) {
    .edit-training-container {
      margin-bottom: 60px !important;
    }
  }
  
  .edit-training-button {
    background-color: #002E6E !important;
    border-radius: 10px !important;
    text-transform: inherit !important;
    font-size: 16px !important;
    margin:16px 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  
  .edit-trainingeditor-container{
    height: 300px;
    margin-top:10px !important ;
    margin-bottom: 60px !important;
  }
  
  .edit-training-texteditor{
    height: 100%;
    border: none;
  }
  
  @media screen and (max-width:492px) {
    .edit-trainingeditor-container {
      margin-bottom: 80px !important;
    }
  }
  @media screen and (max-width:351px) {
    .edit-trainingeditor-container {
      margin-bottom: 105px !important;
    }
  }

  .edit-radio-btn-label{
    margin-right: 12px;
  }

  @media screen and (max-width:325px) {
    .edit-radio-btn-label {
      margin-right: 7px;
    }
  }