.slider2 {
    position: relative;
    max-width:700px;
    height: 425px;
    max-height:425px;
    display: flex;
    margin: 0;
    /* padding:0px 12px; */
    object-fit:cover;  
  }
  
  .image2 {
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    width:700px;
    height:425px;
    object-fit: cover;
    /* margin-top:1rem; */
    display: flex;
    margin-bottom: 1rem;
    bottom: 0;
    overflow: hidden;
    
  }
  
  .right-arrow2 {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 2rem;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow2 {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 2rem;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide2 {
    opacity: 0;
    transition-duration: 1s ease;
    overflow: hidden;
    margin:0;
    display: flex;
    align-items: center;
  }
  
  .slide.active2 {
    opacity: 1;
    display: flex;
    align-items: center;
    transition-duration: 1s;
    overflow: hidden;
    /* transform: scale(1.08); */
    overflow-y: hidden;
  }
