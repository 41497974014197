* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif !important;
}

.referralDashboard-Container {
  max-width: 700px;
  margin-bottom: 100px !important;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .leftDrawer {
    width: 265.34px !important;
  }
}

@media screen and (min-width:960px) {
  .referralDashboard-Container {
    margin-bottom: 60px !important;
  }
}

.Referred-container {
  border: 2px solid #002E6E;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.Referred-image {
  width: 100px;
  height: 100px;
}

.Referred-button {
  background-color: #002E6E !important;
  border-radius: 10px !important;
  text-transform: inherit !important;
  font-size: 16px !important;
  margin: 8px 0px !important ;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}