* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.jobapplications-img {
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
}

.company {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.company-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.company-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;

}

.description-title {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.job_designation {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.job-details-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.job-details-withicon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.job-avatar-style {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.job-details-reacticon {
  width: 18px !important;
  height: 18px !important;
  color: #fff;
}

.job-details-material-ui-Icon {
  width: 22px !important;
  height: 22px !important;
  color: #fff;
}

.jobDescription-text {
  width: 100%;
}

.read-or-hide-jobdescription {
  cursor: pointer;
}

.upload-container {
  border: 2px dashed #002E6E;
  border-radius: 12px;
}

.upload-icon {
  width: 80px !important;
  height: 80px !important;
  color: #002E6E;
}

.job-application-uploaded-container {
  padding: 0px 10px;
}

.job-application-uploaded-icon {
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
}
