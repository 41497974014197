* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.apply-job-details-paper {
  padding: 12px;
  border-radius: 10px !important;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width:600px) {
  .apply-job-details-paper {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.apply-job-details-container {
  max-width: 700px;
  margin-bottom: 85px !important;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .apply-job-details-drawer {
    width: 265.34px !important;
  }

  .apply-job-details-container {
    margin-bottom: 25px !important;
  }
}

.company-picture {
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
  margin-top: 5px;
}

.company {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.company-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.company-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;

}

.description-title {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.job_designation {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.jobDetails-Container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.jobDetails--WithIcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jobAvatar-style {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.jobDetails--ReactIcon {
  width: 18px !important;
  height: 18px !important;
  color: #fff;
}

.jobDetails--materialuiIcon {
  width: 22px !important;
  height: 22px !important;
  color: #fff;
}

.jobDescription-text {
  width: 100%;
}

.read-or-hide-jobdescription {
  cursor: pointer;
}

.upload-container {
  border: 2px dashed #002E6E;
  border-radius: 12px;
}

.upload-icon {
  width: 80px !important;
  height: 80px !important;
  color: #002E6E;
}

.job-pdf-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #002E6E;
  border-radius: 12px;
  padding: 6px;
}

.job-pdf-icon {
  width: 26px !important;
  height: 26px !important;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.job-pdf-filename {
  flex: 1;
  padding: 5px;
  color: white;
}

.apply-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}
