* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.manage-observership-applications-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .manage-observership-applications-drawer {
        width: 265.34px !important;
    }

    .manage-observership-applications-container {
        margin-bottom: 25px !important;
    }
}