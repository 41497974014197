.deactivate-account-container {
    max-width: 700px;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
  }
  
  @media screen and (min-width:960px) {
    .deactivate-account-container {
      margin-bottom: 25px;
    }
  
    .deactivate-account-drawer {
      width: 265.34px !important;
    }
  }
  
.deactivate-account-content{
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
}

.bold-text{
 font-weight: 700 !important;
}

.deactivate-button {
  margin: 12px 0px !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}
