* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.observership {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.observership-company-picture {
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
}

.observership-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.observership-info {
  flex: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;

}

.observership-description-title {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.observership-designation {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.observership-description-container {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 10px 0px;
}

.observership-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.observership-details-with-icon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.observership-avatar-style {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.observership-details-react-icon {
  width: 18px !important;
  height: 18px !important;
  color: #fff;
}

.observership-details-material-ui-icon {
  width: 22px !important;
  height: 22px !important;
  color: #fff;
}

.observership-description-text {
  width: 100%;
}

.read-or-hide-observership-description {
  cursor: pointer;
}

.observership-upload-container {
  border: 2px dashed #002E6E;
  border-radius: 12px;
}

.observership-upload-icon {
  width: 80px !important;
  height: 80px !important;
  color: #002E6E;
}

.observership-apply-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}

.observership-uploaded-document-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #002E6E;
  border-radius: 12px;
  padding: 6px;
}

.observership-file-icon {
  width: 26px !important;
  height: 26px !important;
  color: white;
  cursor: pointer;
  margin: 0px 5px;
}

.observership-file-name {
  flex: 1;
  padding: 5px;
  color: white;
}

.apply-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin: 10px 0px !important;
  padding: 10px 16px !important;
  color: #fff !important;
}