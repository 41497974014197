*{
    font-family: "Source Sans Pro", sans-serif !important
}

.ellipses {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* .MuiDrawer-paperAnchorDockedLeft{
      background-color: #ce1010 !important;
  } */

.desktop  .MuiSvgIcon-root  {
      font-size: 2rem !important;  
  }

.drawer_icon{
    font-size: 2rem !important; 
}

.drawer-react-icon{
    font-size: 1.8rem !important; 
}

.app-logo{
    align-items: center;
    max-width: 100px;
    width: 100%;
    height: 60px;
    object-fit: cover;
    float: left;
    justify-content: flex-start;
}

@media screen and (min-width:960px) {
    .app-logo {
      margin-left: 14rem;
    }
  }

.navbar-link{
    /* float: right;
    justify-content: flex-end;
    flex: auto; */
    margin-left: auto;
}

 
 

   
