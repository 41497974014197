.user-Picture{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.comment{
   margin: 10px 0px; 
   display: flex;
   justify-content: space-between;
   gap: 20px
}

.info{
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.user-Name{
   font-weight: 500; 
}

.date{
   flex:1;
   align-self: flex-end;
   color: #00000099; 
   font-size: 12px;
}

.write-comments{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 10px 0px;
}

.comment-Input{
    flex: 5;
    padding: 10px;
    border: 1px solid gray;
    background-color: transparent;
    color:black;
    border-radius: 10px;
}

.send-Button {
    background-color: #33cac3 !important;
    font-size: 16px !important;
    border-radius: 12px !important;
    text-transform: capitalize !important;
    cursor: pointer !important;
    margin-right: 5px !important;
  }

  @media screen and (max-width:375px) {
    .send-Button {
      font-size: 12px !important;
    }
  }
