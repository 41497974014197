* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.event-checkin-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .event-checkin-drawer {
        width: 265.34px !important;
    }

    .event-checkin-container {
        margin-bottom: 25px !important;
    }
}

.request-camera-button {
    border-radius: 10px !important;
    text-transform: capitalize !important;
    margin: 10px 0px !important;
    padding: 10px 16px !important;
    color: #fff !important;
}
/* 
#reader__header_message {
    margin: 10px !important;
    border-top: none !important;
}

#reader__scan_region {
    display: none !important;
}

#reader__dashboard_section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;  
}

#html5-qrcode-button-camera-permission{
    min-width: 64px;
    padding: 14px;
    border-radius: 6px;
    background-color: #00B9F1;
    font-size: 0.9rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: capitalize !important;
    color: #fff !important;
    cursor: pointer;
} */