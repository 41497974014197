* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.my-observership-applications-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
}

@media screen and (min-width:960px) {
    .my-observership-applications-drawer {
        width: 265.34px !important;
    }

    .my-observership-applications-container {
        margin-bottom: 25px !important;
    }
}

@media screen and (max-width:720px) {
    .my-observership-applications-container {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.my-observership-applications-designation {
    text-transform: capitalize;
    font-weight: 600 !important;
}

.my-observership-applications-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.my-observership-applications-card {
    height: 100%;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.my-observership-applications-card-container {
    margin-top: 20px;
}

.my-observership-applications-icon {
    vertical-align: middle;
    margin-right: 4px;
    margin-left: -4px;
}

.observership-card-content {
    padding: 0px 16px 16px 16px !important;
}

.my-observership-img {
    width: 45px !important;
    height: 45px !important;
    object-fit: cover;
}

.my-observership-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}