* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Source Sans Pro", sans-serif !important;
}

.manageobserverships-card {
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.manageobservership-title {
  text-transform: capitalize;
  font-weight: 600 !important;
}

@media screen and (max-width:600px) {
  .manageobserverships-card {
    margin: 0px 6px;
  }
}

.manageobserverships-card-container {
  margin-top: 20px;
}

.manageobserverships-icon {
  vertical-align: middle;
  margin-right: 4px;
  margin-left: -4px;
}

.manageobserverships-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.manageobserverships-btn {
  margin-left: auto !important;
}

.manageobserverships-button {
  border-radius: 12px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  cursor: pointer !important;
  color: #fff !important;
}

.manageobserverships-delete-btn {
  background-color: #db0505 !important;
}