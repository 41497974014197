.price-card-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .price-card-container {
    margin-bottom: 25px;
  }

  .price-card-drawer {
    width: 265.34px !important;
  }
}

.text-capital {
  text-transform: capitalize;
}

.card-btn {
  border-radius: 18px !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-left: 6px !important;
  color: #fff !important;
}

.card-container {
  padding: 15px;
  border-radius: 15px !important;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.offer-btn {
  border-radius: 20px !important;
  margin-left: 7px !important;
}

.package-btn {
  border-radius: 22px !important;
  text-transform: inherit !important;
  font-size: 18px !important;
  margin: 10px 0px !important;
  padding: 7px 0px !important;
  color: #fff !important;
}