.buyused-container {
  max-width: 700px;
  margin-bottom: 85px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  overflow: hidden;
  width: 100%;
}

@media screen and (min-width:960px) {
  .buyused-container {
    margin-bottom: 25px;
  }

  .buyused-drawer {
    width: 265.34px !important;
  }
}

  .text-capital {
    text-transform:capitalize;
  }
  
  .card-details{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .buyused-product-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .buyused-cards {
    height: 100%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }

  @media screen and (max-width:600px) {
    .buyused-cards {
      margin: 0px 6px;
    }
  }

  .buyused-text {
    font-weight: 700 !important;
  }
   
  /* filter style */

.buyused-search-filter-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.buyused-searchbar-textfield{
   flex: 5;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.buyused-search-textfield {
  margin-top: 0px !important;
}

.buyused-filter-icon {
  width:30px !important;
  height:30px !important;
  margin-right: 5px;
}

.buyused-filter-container {
  position: sticky;
  top: 64px;
  z-index: 2;
  padding: 0px 8px;
}


@media screen and (max-width:600px) {
  .buyused-filter-container {
    top: 55px;
  }
}

.buyused-search-container {
  padding: 12px 0px;
}

@media screen and (min-width:960px) {
  .buyused-filter-container {
    width: calc(100% - 265.34px);
    margin-left: auto;
  }
}

@media screen and (min-width:960px) {
  .buyused-search-container {
    width: 700px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.buyused-dialog-button {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  margin: 10px 0px !important ;
  padding: 8px 16px !important;
  color: #fff !important;
}

 