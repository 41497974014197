* {
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
}

.event-participants-container {
    max-width: 700px;
    width: 100%;
    margin-bottom: 85px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .event-participants-drawer {
        width: 265.34px !important;
    }

    .event-participants-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

@media screen and (max-width:720px) {
    .event-participants-container {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.participants-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.participants-content-container {
    flex: 1;
    overflow-y: auto;
}