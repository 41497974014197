* {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
    font-family: "Source Sans Pro", sans-serif !important;
}

.manage-events-container {
    max-width: 700px;
    margin-bottom: 85px !important;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 155px);
}

@media screen and (min-width:960px) {
    .manage-events-drawer {
        width: 265.34px !important;
    }

    .manage-events-container {
        margin-bottom: 25px !important;
        height: calc(100vh - 95px);
    }
}

@media screen and (max-width:720px) {
    .manage-events-container {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.manage-events-tabs-container {
    position: sticky;
    top: 0;
    z-index: 1;
}

.manage-events-content-container {
    flex: 1;
    overflow-y: auto;
}

/* user posted events style */

.posted-event-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin: 16px 0px;
}

.posted-event-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    border-radius: 8px;
}

@media screen and (min-width: 600px) {
    .posted-event-cards {
        grid-template-columns: repeat(2, 1fr);
        /* 2 cards per row on medium screens */
    }
}

.posted-events-buttons {
    border-radius: 10px !important;
    text-transform: capitalize !important;
    padding: 10px 16px !important;
    color: #fff !important;
}

.posted-events-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.posted-events-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}